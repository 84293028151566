<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />

    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Font <br />Utilities</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              Utilities for controling font-size, font-weight, font-color,
              text-decoration and text-alignment.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            
            <section id="font-size">
              <div class="content-band">
                <h2>Font Size</h2>
                <p class="mb-30">
                  Utilities for controlling the font size of an element.
                </p>

                <div
                  class="flex flex-col max-w800 white"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="container h300 overflow-scroll">
                    <div
                      class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15"
                    >
                      Header font-size variants
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        H1.large
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 3.052rem;
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        H1.xl
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 3.25rem;
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        H1.xxl
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 3.75rem;
                      </div>
                    </div>

                    <div
                      class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15"
                    >
                      font-size variants
                    </div>
                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        .font-15
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 15px;
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        .font-16
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 16px;
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        .font-18
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 18px;
                      </div>
                    </div>

                    <div class="flex border-bottom">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        .font-20
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 20px;
                      </div>
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        .font-25
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: 25px;
                      </div>
                    </div>

                    <div
                      class="container plr-30 ptb-10 flex align-center font-white gray-800 font-15"
                    >
                      Paragraph font-size variants
                    </div>

                    <div class="flex">
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                      >
                        p.small
                      </div>
                      <div
                        class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                      >
                        font-size: .875rem;
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container max-w800 mt-30">
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">H1 </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >large ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >H1</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">H1 </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >xl ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >H1</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">H1 </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >xxl ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >H1</span
                    >&#x3E;
                    <br />
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-15 ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-16 ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-18 ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-20 ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-25 ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >small ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="font-weight">
              <div class="content-band" id="">
                <h2>Font Weight</h2>
                <p class="mb-30">
                  Utilities for controlling the font weight of an element.
                </p>

                <div
                  class="flex flex-col max-w800 white"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .light
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      font-weight: var(--lte);
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .regular
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      font-weight: var(--reg);
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .semibold
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      font-weight: var(--semi);
                    </div>
                  </div>

                  <div class="flex">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .bold
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      font-weight: var(--bold);
                    </div>
                  </div>
                </div>

                <div class="container max-w800 mt-30">
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >light ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >regular ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >semibold ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >bold ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="font-color">
              <div class="content-band" id="">
                <h2>Font Color</h2>
                <p class="mb-30">
                  Utilities for controlling the text color of an element.
                </p>

                <div
                  class="flex flex-col max-w800 white"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .font-white
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      color: white;
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .font-red
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      color: var(--red-800);
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .font-maroon
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      color: var(--maroon-800);
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .font-blue
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      color: var(--blue-800);
                    </div>
                  </div>

                  <div class="flex">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .font-green
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      color: var(--green-800);
                    </div>
                  </div>
                </div>

                <div class="container max-w800 mt-30">
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-white ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-red ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-maroon ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-blue ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >font-green ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="text-decoration">
              <div class="content-band" id="">
                <h2>Text Decoration</h2>
                <p class="mb-30">
                  Utilities for controlling the decoration of text.
                </p>

                <div
                  class="flex flex-col max-w800 white"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .underline
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      text-decoration: underline;
                    </div>
                  </div>

                  <div class="flex">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .no-underline
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      text-decoration: none;
                    </div>
                  </div>
                </div>

                <div class="container max-w800 mt-30">
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >underline ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >no-underline ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                  </div>
                </div>
              </div>
            </section>

            <section id="text-alignment">
              <div class="content-band" id="">
                <h2>Text Alignment</h2>
                <p class="mb-30">
                  Utilities for controlling the alignment of text.
                </p>

                <div
                  class="flex flex-col max-w800 white"
                >
                  <div class="flex">
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Utility class
                    </div>
                    <div class="container plr-30 ptb-20 basis-50 gray-250">
                      Properties
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .text-center
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      text-align: center;
                    </div>
                  </div>

                  <div class="flex border-bottom">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .text-right
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      text-align: right;
                    </div>
                  </div>

                  <div class="flex">
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center border-right min-w100"
                    >
                      .text-left
                    </div>
                    <div
                      class="container plr-30 ptb-20 basis-50 flex align-center min-w100"
                    >
                      text-align: left;
                    </div>
                  </div>
                </div>

                <div class="container max-w800 mt-30">
                  <div class="gray-800 p-20 font-white code">
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >text-center ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >text-right ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                    <br />
                    &#x3C;<span class="code-purple">p </span
                    ><span class="code-blue">class</span>="<span
                      class="code-green"
                      >text-left ...</span
                    >"&#x3E; The quick brown fox ... &#x3C;/<span
                      class="code-purple"
                      >p</span
                    >&#x3E;
                  </div>
                </div>
              </div>
            </section>
          </div>

          <StickyTOC />

        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import StickyTOC from "../StickyTOC";
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Fonts",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    SubFooter,
  },
};
</script>
